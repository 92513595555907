/*
  PLEASE KEEP THE CSS OF THIS APPLICATION VERY MINIMAL. Suggestions:
    1) No fancy SASS (e.g. nesting, variables, macros). The only reason we use SASS is because Bootstrap forces us to.
    2) No overrides or special-case CSS to defeat the DREAM theme.
        - If something looks wrong, figure out what's wrong about the theme and go fix it!
    3) Avoid any custom IDs or classes if you can help it.
*/

@import '~bootstrap/scss/bootstrap';
@import '~@appfolio-im/bootstrap-dream';

.main__content.zen {
  background-image: url(https://af-im-ui.s3.us-east-2.amazonaws.com/static-assets/images/zen-hondou.jpg);
  background-size: cover;
}
